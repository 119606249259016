import 'babel-polyfill'
import 'js/polyfills/customEvent'
import 'js/polyfills/closest'
import lazysizes from 'lazysizes'

// =utils
import 'js/utils/breakpoints'
import 'js/utils/detectTouch'
import 'js/utils/select-handler'

import 'js/components/tracking'

// debug mode
if (document.querySelector('body').classList.contains('-debug')) {
  import(/* webpackChunkName: "debugger" */ 'js/utils/debugger')
}

if (document.querySelector('html[lang="ar"]')) {
  import(/* webpackChunkName: "langLinkAlert" */ 'js/utils/lang-link-alert')
}

//===============================================================/
//  =components
//===============================================================/

/*if (document.querySelector('.cookie-consent')) {
  import(/!* webpackChunkName: "compCookieConsent" *!/ 'js/components/cookieConsent')
}*/

if (document.querySelector('.js-quote-tabs')) {
  import(/* webpackChunkName: "quoteTabs" */ 'js/components/quote-tabs')
}

if (document.querySelector('.js-quote-selection')) {
  import(/* webpackChunkName: "fixedForms" */ 'js/components/fixed-forms')
}

if (document.querySelector('.js-custom-form')) {
  import(/* webpackChunkName: "customForm" */ 'js/components/custom-form')
}

if (document.querySelector('.js-wellbeing-hub-carousel')) {
  import(
    /* webpackChunkName: "wellbeingHubCarousel" */ 'js/components/wellbeingHubCarousel'
  )
}

//===============================================================/
//  =blocks
//===============================================================/

if (document.querySelector('.b-packages-block')) {
  import(/* webpackChunkName: "packagesBlock" */ 'js/blocks/packages')
}

if (document.querySelector('.js-accordion')) {
  import(/* webpackChunkName: "accordionBlock" */ 'js/blocks/accordion')
}

if (document.querySelector('.b-header-banner-block')) {
  import(/* webpackChunkName: "headerBanner" */ 'js/blocks/headerBanner')
}

if (document.querySelector('.b-quick-links-block, .js-quick-links')) {
  import(/* webpackChunkName: "quickLinks" */ 'js/blocks/quickLinks')
}

if (document.querySelector('.b-quote-block')) {
  import(/* webpackChunkName: "quote" */ 'js/blocks/quote')
}

if (document.querySelector('.b-stat-block')) {
  import(/* webpackChunkName: "stat" */ 'js/blocks/stat')
}

if (document.querySelector('.b-key-block')) {
  import(/* webpackChunkName: "key" */ 'js/blocks/key')
}

if (document.querySelector('.b-wellbeing-accordion-block')) {
  import(
    /* webpackChunkName: "wellbeingAccordion" */ 'js/blocks/wellbeingAccordion'
  )
}

//===============================================================/
//  =layout
//===============================================================/

if (document.querySelector('.site-header-container')) {
  import(/* webpackChunkName: "layoutNav" */ 'js/layouts/nav')
}

//===============================================================/
//  =pages
//===============================================================/

if (document.querySelector('.js-quote-form')) {
  import(/* webpackChunkName: "quoteFormPage" */ 'js/pages/quote-form')
}
